<cp-rp-accordion>
  <cp-rp-accordion-item
    [focusId]="item.measure.measureId"
    *ngFor="let item of items"
    [title]="
      item.measure.measureId === ROOT_MEASURE_ID
        ? reportData.assessment.name
        : item.measure.label
    ">
    <ng-container *cpRpAccordionContent>
      <cp-rp-question-answer
        *ngFor="let questionItem of item.questions; let i = index"
        [question]="questionItem.question"
        [questionNumber]="i + 1"
        [participantSelection]="questionItem.selection" />
    </ng-container>

    <div *cpRpAccordionChart class="d-flex mb-5 my-md-0 w-100">
      <span class="text-nowrap me-3"
        >{{ item.totalCorrectQuestions }} of
        {{ item.totalScoredQuestions }} correct</span
      >
      <cp-rp-score-chart
        [progress]="item.totalCorrectQuestions / item.totalScoredQuestions"
        [displayValue]="
          ((item.totalCorrectQuestions / item.totalScoredQuestions) * 100
            | round) + '%'
        " />
    </div>
  </cp-rp-accordion-item>
</cp-rp-accordion>
