<cp-rp-accordion>
  <cp-rp-accordion-item
    [title]="title || measure.label"
    [focusId]="measure.measureId"
    *ngFor="let measure of measures"
    [description]="templateService.render(description, measure.measureId)">
    <cp-rp-component-renderer
      [measureId]="measure.measureId"
      [components]="component.children"
      *cpRpAccordionContent />

    <ng-container *ngIf="showChart">
      <cp-rp-measure-score-chart
        *cpRpAccordionChart
        [measureId]="measure.measureId"
        [showValue]="showChartValue"
        [tailStart]="
          showChartTails ? chartTailStart || measure.tailStart : undefined
        "
        [tailEnd]="showChartTails ? chartTailEnd || measure.tailEnd : undefined"
        [showRangeTicks]="false" />
    </ng-container>
  </cp-rp-accordion-item>
</cp-rp-accordion>
