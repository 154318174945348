<div class="row">
  <div class="col" ngbAccordion #accordion="ngbAccordion">
    <div
      [ngbAccordionItem]="accordionId + i"
      *ngFor="let item of items; let i = index"
      [cpRpFocusable]="item.focusId"
      class="mb-2"
      [ngClass]="{
        showing: accordion.isExpanded(accordionId + i),
      }">
      <div
        ngbAccordionHeader
        ngbAccordionToggle
        class="d-flex header cursor-pointer p-3">
        <div
          class="header-title d-flex flex-fill align-items-baseline flex-row-reverse flex-md-row justify-content-between justify-content-md-start">
          <i class="me-md-3 fa-light fa-chevron-right d-print-none"></i>
          <div class="d-flex flex-column">
            <span class="title" [class.mb-md-3]="!item.description">
              {{ item.title }}
            </span>
            <p class="description mb-0" *ngIf="item.description">
              {{ item.description | stripTags }}
            </p>
          </div>
        </div>
        <div
          class="w-35 d-flex justify-content-end pe-3"
          *ngIf="breakpoints.current().largerThan('small')">
          <ng-container
            *ngTemplateOutlet="$any(item.chart?.template) ?? null" />
        </div>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div
              class="accordion-chart"
              *ngIf="breakpoints.current().smallerThan('medium')">
              <ng-container
                *ngTemplateOutlet="$any(item.chart?.template) ?? null" />
            </div>
            <ng-container
              *ngTemplateOutlet="$any(item.content?.template) ?? null" />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
