import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { PageService } from '../../services/page.service';
import { TaskQueue } from '@compass/helpers';

@Component({
  selector: 'cp-rp-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent extends PublicReportComponent implements OnInit {
  @Input()
  name: string = '';

  @Input()
  open: boolean = false;

  get pageId(): string {
    return this.component.id;
  }

  constructor(
    private readonly _pageService: PageService,
    private readonly _elementRef: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.component.isTopComponent) return;

    TaskQueue.scheduleOnMacroQueue(() => {
      this._pageService.registerPage(this);
    });
  }

  focusMeasure(measureId: string): void {
    setTimeout(
      () =>
        this._elementRef.nativeElement
          ?.querySelector(`[data-focusable="${measureId}"]`)
          ?.scrollIntoView(),
      400,
    );
  }

  protected isActive(): boolean {
    return this._pageService.activePage?.pageId === this.pageId;
  }
}
