import {
  AfterViewInit,
  Component,
  ContentChildren,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Breakpoints } from '@compass/environment';
import { AccordionItemComponent } from '../accordion-item/accordion-item.component';
import { Random } from '@compass/helpers';
import {
  NgbAccordionDirective,
  NgbAccordionItem,
} from '@ng-bootstrap/ng-bootstrap';
import { AccordionService } from '../../services/accordion.service';

@Component({
  selector: 'cp-rp-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterViewInit, OnDestroy {
  protected readonly accordionId = Random.id();

  @ContentChildren(AccordionItemComponent)
  items!: QueryList<AccordionItemComponent>;

  @ViewChild(NgbAccordionDirective)
  protected accordion?: NgbAccordionDirective;

  @ViewChildren(NgbAccordionItem)
  protected accordionPanels!: QueryList<NgbAccordionItem>;

  constructor(
    protected readonly breakpoints: Breakpoints,
    private readonly _accordionService: AccordionService,
  ) {}

  ngAfterViewInit(): void {
    this.accordion?.expandAll();

    this._accordionService.register(...this.accordionPanels.toArray());
  }

  ngOnDestroy(): void {
    this._accordionService.unregister(...this.accordionPanels.toArray());
  }
}
